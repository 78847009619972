import React from 'react';

import LayoutContainerLess from "../layout/layoutContainerLess";
import Seo from '../layout/seo';
import { Container, Button } from 'react-bootstrap';
import Heading from '../components/heading/heading';

import { withTranslation } from 'react-i18next';
import Link from '../components/net/link';

//import './campaignTemplate.scss';

/**
 * The Knowledge Template includes a menu bar on the left.
 */
class CampaignTemplate extends React.Component {
  render() {
    const { frontmatter, t, html } = this.props; //no need for this.props.html

    let lang = "en";

    if (frontmatter.lang) {
      lang = frontmatter.lang;
    }


    let hero_image_desktop = "url('" + frontmatter.campaignHeroimageDesktop + "')";
    let hero_image = "url('" + frontmatter.campaignHeroimage + "')";
    return (

      <LayoutContainerLess location={this.props.location}>
        <Seo lang={lang} title={this.props.frontmatter.title} location={this.props.location} />
        <>
      <Container fluid className="campaign-hero d-none d-sm-block hero-section" 
      style={{backgroundImage: hero_image_desktop}}>
      <Container className="text-center">
      
      
      <Heading as="h3" type="display">{frontmatter.campaignClaim}</Heading>

      <div className="campaign-call">{frontmatter.campaignCall}</div>

      </Container>
      <Container style={{marginTop: "8rem", marginBottom: "8rem"}}>
      <div className="text-center">
      
      <Button href="https://app.nutrfy.com/signup" target="_blank" rel="noopener noreferrer" variant="primary" size="lg">
    {t('components.button_join')}</Button>
      
      <Link to="/why-nutrfy" className="btn btn-outline-secondary btn-lg ml-4">{t('fragments.learn_more')}</Link>
      </div>
          
        </Container>


      



    </Container>

      <div dangerouslySetInnerHTML={{ __html: html }}/>




    <Container fluid className="campaign-hero-mobile d-sm-none"
    style={{backgroundImage: hero_image}}>
    <Container>
      
      
        <Heading as="h3" type="display">{frontmatter.campaignClaim}</Heading>

      </Container>

    </Container>
    </>




      </LayoutContainerLess>
    )
  }
}
export default withTranslation()(CampaignTemplate);