import React from "react"
import { graphql } from "gatsby"

//import './defaultTemplate';
import KnowledgeTemplate from './knowledgeTemplate';
import StandardTemplate from './standardTemplate';
import CampaignTemplate from './campaignTemplate';
import BlogPageTemplate from './blogPageTemplate';
import FaqPageTemplate from './faqPageTemplate';

/**
 * The Default Template will either load the Knowledge Template or the 
 * Standard Template, based on the path handed to it.
 * 
 * 
 * 
 */
class Template extends React.Component {
  render() {
    const {data} = this.props;
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark
    
    let showKnowledgeTemplate = false;
    let showCampaignTemplate = false;
    let showBlogPageTemplate = false;
    let showFaqPageTemplate = false;

    if (this.props.location.pathname.substring(4,13) === 'knowledge') {
      //if the location is prefixed with 'knowledge', we will render the knowledge template
      showKnowledgeTemplate = true;
    }
    if (this.props.location.pathname.substring(4,13) === 'campaigns') {
      showCampaignTemplate = true;
    }
    if (this.props.location.pathname.substring(4,8) === 'blog') {
      showBlogPageTemplate = true;
    }
    if (this.props.location.pathname.substring(4,7) === 'faq') {
      showFaqPageTemplate = true;
    }

    
    return (
      <>
        {!(showKnowledgeTemplate || showCampaignTemplate || showBlogPageTemplate || showFaqPageTemplate) ?
          <StandardTemplate html={html} location={this.props.location} frontmatter={frontmatter} />
          : null
        }


        {showKnowledgeTemplate ?
          <KnowledgeTemplate html={html} frontmatter={frontmatter} location={this.props.location} />
          :
          null
        }
        {showCampaignTemplate ?
          <CampaignTemplate html={html} frontmatter={frontmatter} location={this.props.location} />
          :
          null
        }

        {showBlogPageTemplate ?
          <BlogPageTemplate html={html} frontmatter={frontmatter} location={this.props.location} />
          :
          null
        }

        {showFaqPageTemplate ?
          <FaqPageTemplate html={html} frontmatter={frontmatter} location={this.props.location} oprops={this.props}/>
          :
          null
        }




        {/*
            */}
      </>
    )
  }
}

    export default Template;


export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        lang
        abstract
        featureImage
        campaignHeroimage
        campaignHeroimageDesktop
        campaignClaim
        campaignCall
        relatedlinks {
          text
          title
          link
        }
      }
    }
  }
  `
  /*
  export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/blog/my-first-post" } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        lang
      }
    }
  }
  `*/
