import React from 'react';
import Sidebar from '../components/knowledge/sidebar';
import { Col, Row } from 'react-bootstrap';
import Layout from "../layout/layout";
import Seo from '../layout/seo';
import { Container } from 'react-bootstrap';


/**
 * The Knowledge Template includes a menu bar on the left.
 */
class KnowledgeTemplate extends React.Component {
render() {
  let lang = "en";
  if (this.props.frontmatter.lang) {
    lang = this.props.frontmatter.lang;
  }


  return (
    <Layout location={this.props.location}>
    <Seo lang={lang} title={this.props.frontmatter.title} location={this.props.location}/>
    <Container>
  <div>
      <div className="content-page-container mt-4">
      <div className="content-page">

    <Row>
    <Col sm={{order:1}}
    md={{order:12}}
    lg={8}>
    <div
    className="knowledge-page-content"
    dangerouslySetInnerHTML={{ __html: this.props.html }}
  />

    </Col>
    <Col sm={{order:12}}
    md={{order:1}}
    lg={4}>
    <Sidebar frontmatter={this.props.frontmatter}/>
    </Col>

  </Row>
  </div>
        </div>
      
        </div>
        </Container>
        </Layout> 
  )
}
}
export default KnowledgeTemplate;