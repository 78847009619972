import React from 'react';
import Layout from "../layout/layout";
import Seo from '../layout/seo';

/**
 * Used for rendering blog articles.
 * 
 * This template gets called if we hit a markdown page. (e.g. /en/blog/my-post)
 */
class FaqPageTemplate extends React.Component {
  render() {
    let lang = "en";
    if (this.props.frontmatter.lang) {
      lang = this.props.frontmatter.lang;
    }
    console.log(this.props);
    return (
      <Layout location={this.props.location}>
        <Seo
          lang={lang}
          title={this.props.frontmatter.title}
          description={this.props.frontmatter.abstract}
          location={this.props.location}
          image={this.props.frontmatter.featureImage}
        />
        <div
          className="faq-content"
          dangerouslySetInnerHTML={{ __html: this.props.html }}
        />



      </Layout>
    )
  }
}

export default FaqPageTemplate;