import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import SidebarLink from './sidebarlink';
import Heading from '../heading/heading';
import { Trans } from 'react-i18next';
//import './sidebar.scss';

class Sidebar extends React.Component {

  render() {
    const edges = this.props.data.allMarkdownRemark.edges;

    let lang = this.props.frontmatter.path.substring(1,3);
    let path = '/' + lang + '/knowledge/';
    let pages = edges.filter(item => {
      return item.node.frontmatter.path.startsWith(path) && item.node.frontmatter.rank != null;
    })
    let nodes = pages.map(page => {
      let showLink = page.node.frontmatter.path !== this.props.frontmatter.path;
      let title = page.node.frontmatter.title;
      let link = page.node.frontmatter.path.substring(3); //Link will add the language in front automatically
      
      return (
        <SidebarLink showLink={showLink} title={title} link={link} key={link} />        
      )
    })
    //console.log(pages);
    
    return (
      <div>
        <Heading as="h4" className="sidebar-header">
        <Trans i18nKey='Knowledge._headline'/>
        </Heading>
        <ul className="sidebar-toc ml-0 pl-0">
          {nodes}
        </ul>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___rank]}) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              rank
              abstract
            }
          }
        }
      }}
    `}
    render={data => <Sidebar data={data} {...props} />}
  />
)
/*
export const pageQuery = graphql`
    query {
      allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___rank]}) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              rank
              abstract
            }
          }
        }
      }
    }`
    */