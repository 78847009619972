import React from 'react';
import Layout from "../layout/layout";
import Seo from '../layout/seo';
import { Container } from 'react-bootstrap';

class StandardTemplate extends React.Component {

  render() {
    let lang = "en";
    if (this.props.frontmatter) {    
      if (this.props.frontmatter.lang) {
        lang = this.props.frontmatter.lang;
      }
    }
    return (

      <Layout location={this.props.location}>
      <Seo lang={lang} title={this.props.frontmatter.title} location={this.props.location} />
      <Container>
    <div>
        <div className="content-page-container mt-4">
        <div className="content-page">


      <div
      className="content-page-content"
      dangerouslySetInnerHTML={{ __html: this.props.html }}
    />
              </div>
        </div>
      
        </div>
        </Container>
        </Layout> 
  
    )
  }
}
export default StandardTemplate;