import React from 'react';
import Layout from "../layout/layout";
import Seo from '../layout/seo';
import BlogContent from '../content/blog/blogcontent';
//import './blogPageTemplate.scss';

/**
 * Used for rendering blog articles.
 * 
 * This template gets called if we hit a markdown page. (e.g. /en/blog/my-post)
 */
class BlogPageTemplate extends React.Component {
  render() {
    let lang = "en";
    if (this.props.frontmatter.lang) {
      lang = this.props.frontmatter.lang;
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          lang={lang}
          title={this.props.frontmatter.title}
          description={this.props.frontmatter.abstract}
          location={this.props.location}
          image={this.props.frontmatter.featureImage}
          />
        <BlogContent frontmatter={this.props.frontmatter} html={this.props.html} />
      </Layout>
    )
  }
}

export default BlogPageTemplate;